import { Component, Input, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth-service.service';
import { EnvService } from 'src/app/core/services/env.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';

interface MicroEngineWebElement extends HTMLElement {

  provider: any;
}

// #region Constants

const ELEMENT_ID = 'microFrontElement';

// #endregion

/**
 * Custom web element wrapper component to load custom web elements
 * This component is used to load custom web elements in the application to render the custom web elements
 * as micro front end components
 */
@Component({
  selector: 'app-custom-web-element-wrapper',
  template: `<div #microFrontElement style="display: block; height: 100%;" [style.overflow-y]="scrollable ? 'auto' : 'hidden'" ></div>`,
})
export class CustomWebElementWrapperComponent implements AfterViewInit {

  @Input() elementName: string;

  @Input() elementData: any;

  @Input() scrollable: boolean = true;

  @ViewChild(ELEMENT_ID) webElementContainer: ElementRef;

  /// Provider object to pass the required data to the custom element
  public provider: any = {
    tenantId: this.env.APIS.TENANTID,
    userId: this.auth.userId,
    subscriptionKey: this.env.SUBSCRIPTION_KEY,
    permisions: this.permisions.getReducedPermisions(),
    endpoints: {
      doAdmin: this.env.APIS.DIGITAL_ONBOARDING_ADMIN,
      products: this.env.APIS.ORIGINATIONS,
      queryable: this.env.APIS.QUERYABLE,
      automationFlow: this.env.APIS.AUTOMATION_FLOW,
      reports: this.env.APIS.REPORTS,
      tenants: this.env.APIS.TENANTS
    },
    getToken: async () => {

      return this.auth.accessToken;
    }
  };

  constructor(private auth: AuthService, private env: EnvService, private permisions: PermissionsService) { }

  ngAfterViewInit() {

    this.loadCustomElement();
  }

  /**
   * Function to load custom element in the application
   */
  loadCustomElement() {

    const customElement: MicroEngineWebElement = document.createElement(this.elementName) as MicroEngineWebElement;
    customElement.provider = this.provider;
    this.webElementContainer.nativeElement.appendChild(customElement);

    // Add additional attributes to the custom element
    if (this.elementData) {

      for (const key in this.elementData) {

        if (this.elementData.hasOwnProperty(key)) {

          customElement[key] = this.elementData[key];
        }
      }
    }
  }
}
